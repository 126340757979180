<template>
    <v-container style="padding-top: 59px; background-color: rgba(255, 255, 255, 0.5);">
        <v-row>
            <v-col class="mainCont" style="min-width: 100vw; position: fixed; left: 0; padding-left: 0px;z-index: 1;">
                <div class="mainTitleConfSign"
                    style="background-color: white!important; color: black; font-weight: bold; padding: 4px; font-size: 10px; min-width: 100vw; border-color: #da6a2d; display: flex;">
                    {{ titolo }}
                    <div style="margin-left: 25px">Pages:&nbsp;{{ numPage }}&nbsp;/&nbsp;{{ pagesTotal }}</div>
                    <div style="margin-left: 25px">Signs:&nbsp;{{ numSigns }}&nbsp;/&nbsp;{{ totSigns }}</div>
                    <!-- <div style="display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 85%;">
                        <div style="font-size:14px">Click on the signature button below to add your signatures and then
                            click on the save button to save your signatures</div>
                    </div> -->
                </div>
            </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col cols="12" md="12" style="display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center">
                    <div id="pdf-container" ref="pdfContainer"
                        style="width: 85%; height: 90vh; overflow-y: auto; position: relative;">
                        <div v-for="pageNumber in totalPages" :key="pageNumber" :ref="'pdfPage-' + pageNumber"
                            style="position: relative;">
                            <canvas :id="'pdf-canvas-' + pageNumber" :width="pdfWidth" :height="pdfHeight"
                                style="border: 1px solid #000; margin-bottom: 20px;">
                            </canvas>
                        </div>
                        <!-- Riquadri di evidenziazione per le coordinate salvate -->
                        <div v-for="(coord, index) in coordinates" :key="`highlight-${index}`" class="highlight-box-set"
                            :ref="`highlight-${index}`"
                            :style="{ left: `${coord.canvasX}px`, top: `${coord.canvasY}px`, position: 'absolute' }"
                            @click.stop="openPopupForSign(index)">
                            <img v-if="coord.signatureImg" :src="coord.signatureImg" alt="Signature"
                                class="signature-image" />
                            <div class="text-container">
                                <div v-if="coord.text1" class="signature-text">{{ coord.text1 }}</div>
                                <div v-if="coord.text2" class="signature-text">{{ coord.text2 }}</div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-form>

        <div class="contpopupsignatureviewfile" style="display:none">

            <template>
                <modal name="popupSignatureViewFile" :clickToClose="false" :width="900" :height="322">

                    <v-row style="margin-bottom: 0px;text-align: center;padding-top:10px">

                        <v-col cols="12" md="12" class="titleSignature" style="font-weight: bold;">
                            SIGN FOR ACCEPTANCE
                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px;">

                        <v-col cols="12" md="12" style="text-align: center;">

                            <VueSignaturePad width="850px" height="200px" ref="signaturePad" class="contSignature"
                                :options="{ minWidth: 0.2, maxWidth: 1.0, penColor: 'black' }" />

                        </v-col>

                    </v-row>



                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">



                                <v-img alt="" class="btn-delete-signed" contain src="@/assets/icon_delete_alertmsg.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Clear" @click="btnResetSignatureViewFile" />


                                <v-img alt="" class="btn-confirm-signed" contain
                                    src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmSignatureViewFile" />





                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupsignatureviewfilemobile" style="display:none">

            <template>
                <modal name="popupSignatureViewFileMobile" :clickToClose="false" :width="400" :height="322">

                    <v-row style="margin-bottom: 0px;text-align: center;padding-top:10px">

                        <v-col cols="12" md="12" class="titleSignature" style="font-weight: bold;">
                            SIGN FOR ACCEPTANCE
                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px;">

                        <v-col cols="12" md="12" style="text-align: center;">

                            <VueSignaturePad width="350px" height="200px" ref="signaturePad" class="contSignature"
                                :options="{ minWidth: 0.2, maxWidth: 1.0, penColor: 'black' }" />

                        </v-col>

                    </v-row>



                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">



                                <v-img alt="" class="btn-delete-signed" contain src="@/assets/icon_delete_alertmsg.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Clear"
                                    @click="btnResetSignatureViewFileMobile" />


                                <v-img alt="" class="btn-confirm-signed" contain
                                    src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmSignatureViewFileMobile" />





                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupotplogin" style="display:none">

            <template>
                <modal name="popupOtpLogin" :clickToClose="false" :width="262" :height="221">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;font-size:12px;text-align: center;">

                            OTP code sent to you via SMS

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="text" v-model="valueOtpLogin" label="" id="fldValueOtpLoginPrjweb"
                                onkeydown="if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace') event.preventDefault();">
                            </v-text-field>



                        </v-col>

                    </v-row>

                    <v-row v-if="showResend">

                        <v-col cols="12" md="12" style="display: flex;
justify-content: center;
padding-top: 0px!important;
margin-top: 0px!important;
font-size:12px">

                            <div style="font-weight: bold;
cursor: pointer;" class="clReinviaOtp2">Resend</div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-confirm-otp-login" contain
                                    src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmOtpLogin" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import router from ".././router";
import apimessaggi from "../utils/messaggi/apimessaggi";

import axios from 'axios';

import Vue from "vue";
import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);

import {
    bus
} from "../main";


export default {
    data() {
        return {
            pdfUrl: "",
            coordinates: [],
            coordinates_original: [],
            totalPages: 0,
            pdfWidth: 595,
            pdfHeight: 842,
            scale: 1.5,
            pages: {}, // Cache delle pagine PDF
            titolo: "Sign Document",
            infosigned: "",
            valueOtpLogin: "",
            showResend: false,
            selectedIndex: null,
            signatureData: null,
            viewPages: "",
            numPage: 1,
            pagesTotal: 0,
            numSigns: 0,
            totSigns: 0,
            currentPage: 1,
            currentIndex: null,
        };
    },

    destroyed() {

        bus.$off("bottomIconClickEvent");
    },


    mounted() {

        /*eslint-disable no-undef*/

        var pointerVue = this;

        window.callEvent = 1;

        bus.$off("bottomIconClickEvent");

        this.setupButtons();

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {



                case "btn_sign":

                    console.log("btn_sign");

                    this.setSign();

                    break;

                case "btn_save":

                    console.log("btn_save");

                    this.validate();

                    break;





                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '.clReinviaOtp2', function (event) {
                event.preventDefault();

                console.log("clReinviaOtp2");


                pointerVue.showResend = false;

                pointerVue.$modal.hide('popupOtpLogin');


                pointerVue.getDataForOtpResend();


            });



        });


        setTimeout(() => {

            this.viewAttachment();

        }, 100);

        setTimeout(() => {

            this.$swal({
                icon: "warning",
                text: "Click in the indicated box to sign",
                showConfirmButton: true,
                confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertmsg.png' />",
            });

        }, 3000);

    },
    methods: {


        updateCountSigns: function () {

            var that = this;


            var tot_signs = 0;
            var tot_signed = 0;

            for (var xx = 0; xx < that.infosigned.length; xx++) {

                console.log("ELE INFO SIGNED: ", that.infosigned[xx].page);

                tot_signs = tot_signs + 1;

                if (that.infosigned[xx].signed == 1) {
                    tot_signed = tot_signed + 1;
                }


            }

            that.numSigns = tot_signed;
            that.totSigns = tot_signs;


        },





        getDataForOtpResend: async function () {

            var that = this;


            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getDataForOtp(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDataForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    //that.getValueForOtp(res.data.Username, "emt", res.data.Mobile, res.data.Prefix);
                    that.getValueForOtp(res.data.Username, "alertmsg", res.data.Mobile, res.data.Prefix);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        sendMailDocSigned: async function () {



            var that = this;

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.sendMailDocSigned(
                v_token,
                window.currentItemRow.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendMailDocSigned", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    setTimeout(() => {

                        that.$swal({
                            icon: "success",
                            text: "Document signed successfully, an email has been sent to you",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        router.push({
                            path: "/initMessages"
                        });


                    }, 200);



                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        signedDocumentWithPython: async function (v_input_pdf_path, v_output_pdf_path, v_image_path) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            const formData = new FormData();
            formData.append('input_pdf_path', v_input_pdf_path);
            formData.append('output_pdf_path', v_output_pdf_path);
            formData.append('image_path', v_image_path);
            formData.append('v_user', atob(v_token));
            formData.append('footer_option', 1);
            formData.append('scale', 1.5);



            

            //var v_url = "http://127.0.0.1:8000/edit_pdf/";
            var v_url = "https://filestransfer.it:8000/edit_pdf/";

            try {


                const response = await axios.post(v_url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log("res from edit-pdf", response);


                that.$root.$children[0].showProgress = false;

                setTimeout(() => {

                    that.sendMailDocSigned();
                    
                }, 200);

                

            } catch (error) {

                that.$root.$children[0].showProgress = false;

                that.$swal({
                    icon: "error",
                    text: "Error signing document",
                    showConfirmButton: false,
                    timer: 3000
                });

            }


        },

        saveData: async function () {

            var that = this;

            console.log("INFO SIGNS: ", that.infosigned);

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.saveDataSigns(
                v_token,
                window.currentItemRow.id,
                JSON.stringify(that.infosigned)
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveDataSigns", res);

                if (res.data.Result == "OK") {

                    that.signedDocumentWithPython(res.data.PathPdfInput,
                        res.data.PathPdfOutput,
                        res.data.PathImagesSigns);

                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }








            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );




        },


        validate() {

            var that = this;

            console.log("INFO SIGNS: ", that.infosigned);

            var errore = "";


            var count = 0;


            for (var xx = 0; xx < that.infosigned.length; xx++) {


                if (that.infosigned[xx].signed == 0) // posizione non firmata
                {
                    count = count + 1;
                }


            }


            if (count > 0) {
                errore = "Complete all signatures on the document";
            }


            if (errore == "") {

                console.log("ok");

                this.saveData();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }



        },


        btnConfirmOtpLogin: function () {

            var that = this;


            if (this.valueOtpLogin != "") {

                console.log("OTP INP: ", this.valueOtpLogin);
                console.log("ENC OTP INP: ", btoa(this.valueOtpLogin));

                if (window.myVal == btoa(this.valueOtpLogin)) {

                    this.$modal.hide('popupOtpLogin');

                    console.log("INFO SIGNS", that.infosigned);

                    that.saveTextSign();

                    if (that.selectedIndex !== null) {

                        that.coordinates[that.selectedIndex].signatureImg = that.signatureData;

                        that.coordinates[that.selectedIndex].text1 = "Firmato digitalmente il " + this.getCurrentDate() + " alle " + this.getCurrentTime();

                        that.coordinates[that.selectedIndex].text2 = "tramite codice OTP inviato via SMS";

                    }

                    setTimeout(() => {

                        that.setSign();

                    }, 200);

                    //this.loginAction2(this.input.username);

                    // this.saveSignedDocument();


                }
                else {


                    this.$swal({
                        icon: "error",
                        text: "Incorrect OTP value",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        this.valueOtpLogin = "";
                        $("#fldValueOtpLoginPrjweb").focus();

                    }, 2500);


                }



            }
            else {


                $("#fldValueOtpLoginPrjweb").focus();

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },

        saveImageSign: function (v_data) {

            var that = this;

            var pageNumber = 0;


            for (var xx = 0; xx < that.infosigned.length; xx++) {


                if (pageNumber == 0) {

                    if (that.infosigned[xx].signed == 0) // posizione non firmata
                    {

                        that.infosigned[xx].imageSign = v_data;

                        pageNumber = that.infosigned[xx].page;
                    }

                }


            }

        },

        getCurrentDate: function () {

            const today = new Date(); // Ottiene la data corrente
            const day = String(today.getDate()).padStart(2, '0'); // Ottiene il giorno e lo formatta
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Ottiene il mese (0-11) e lo formatta
            const year = today.getFullYear(); // Ottiene l'anno

            return `${day}/${month}/${year}`;

        },

        getCurrentTime: function () {

            const now = new Date(); // Ottiene la data e ora correnti
            const hours = String(now.getHours()).padStart(2, '0'); // Ottiene l'ora e la formatta
            const minutes = String(now.getMinutes()).padStart(2, '0'); // Ottiene i minuti e li formatta

            return `${hours}:${minutes}`; // Restituisce l'ora formattata

        },

        saveTextSign: function () {

            var that = this;

            var pageNumber = 0;


            for (var xx = 0; xx < that.infosigned.length; xx++) {


                if (pageNumber == 0) {

                    if (that.infosigned[xx].signed == 0) // posizione non firmata
                    {

                        that.infosigned[xx].testo1 = "Firmato digitalmente il " + this.getCurrentDate() + " alle " + this.getCurrentTime();
                        that.infosigned[xx].testo2 = "tramite codice OTP inviato via SMS";

                        that.infosigned[xx].signed = 1;

                        that.infosigned[xx].indexElement = that.currentIndex;

                        pageNumber = that.infosigned[xx].page;
                    }

                }


            }

            setTimeout(() => {

                that.updateCountSigns();

            }, 200);

        },

        getWindowMobile: function () {

            if (window.innerWidth <= 768) {

                return 1;

            }
            else {

                return 0;

            }

        },


        getValueForOtp: async function (v_username, v_name_product, v_mobile, v_prefix_mobile) {



            var that = this;

            that.$modal.hide('popupSignatureViewFile');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getValueForOtp(
                v_username,
                v_name_product,
                v_mobile,
                v_prefix_mobile
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getValueForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    window.myVal = res.data.ValReturn;


                    setTimeout(() => {

                        that.showResend = true;

                        that.valueOtpLogin = "";

                        $(".contpopupotplogin").show();

                        that.$modal.show('popupOtpLogin');



                        setTimeout(() => {

                            $("#fldValueOtpLoginPrjweb").focus();

                        }, 300);

                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        getValueForOtpMobile: async function (v_username, v_name_product, v_mobile, v_prefix_mobile) {



            var that = this;

            that.$modal.hide('popupSignatureViewFileMobile');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getValueForOtp(
                v_username,
                v_name_product,
                v_mobile,
                v_prefix_mobile
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getValueForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    window.myVal = res.data.ValReturn;


                    setTimeout(() => {

                        that.showResend = true;

                        that.valueOtpLogin = "";

                        $(".contpopupotplogin").show();

                        that.$modal.show('popupOtpLogin');



                        setTimeout(() => {

                            $("#fldValueOtpLoginPrjweb").focus();

                        }, 300);

                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        getDataForOtp: async function () {

            var that = this;

            // recupero immagine firma fatta
            const { isEmpty, data } = that.$refs.signaturePad.saveSignature();
            window.dataSigned = data;

            console.log(isEmpty);

            that.saveImageSign(data);

            that.signatureData = data;



            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getDataForOtp(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDataForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    that.getValueForOtp(res.data.Username, "emt", res.data.Mobile, res.data.Prefix);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        getDataForOtpMobile: async function () {

            var that = this;

            // recupero immagine firma fatta
            const { isEmpty, data } = that.$refs.signaturePad.saveSignature();
            window.dataSigned = data;

            console.log(isEmpty);

            that.saveImageSign(data);

            that.signatureData = data;



            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getDataForOtp(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDataForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    that.getValueForOtp(res.data.Username, "emt", res.data.Mobile, res.data.Prefix);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        btnConfirmSignatureViewFile: function () {

            var that = this;

            var errore = "";


            if (that.$refs.signaturePad.isEmpty()) {
                errore = "La firma è richiesta";
            }

            if (errore == "") {

                console.log("ok");


                that.getDataForOtp();



            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }



        },

        btnConfirmSignatureViewFileMobile: function () {

            var that = this;

            var errore = "";


            if (that.$refs.signaturePad.isEmpty()) {
                errore = "La firma è richiesta";
            }

            if (errore == "") {

                console.log("ok");


                that.getDataForOtpMobile();



            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }



        },


        btnResetSignatureViewFile: function () {

            this.$refs.signaturePad.undoSignature();

        },

        btnResetSignatureViewFileMobile: function () {

            this.$refs.signaturePad.undoSignature();

        },


        openPopupForSign: function (index) {

            var that = this;

            that.selectedIndex = index;

            that.signatureData = null;


            var openPopup = 1;

            for (var xx2 = 0; xx2 < that.infosigned.length; xx2++) {

                if (that.infosigned[xx2].signed == 1) {

                    if (that.infosigned[xx2].indexElement == index) {
                        openPopup = 0;
                    }

                }


            }


            if (openPopup == 1) {


                if (this.getWindowMobile() == 0) {

                    $(".contpopupsignatureviewfile").show();

                    this.$modal.show('popupSignatureViewFile');
                }

                if (this.getWindowMobile() == 1) {

                    $(".contpopupsignatureviewfilemobile").show();

                    this.$modal.show('popupSignatureViewFileMobile');
                }




            }





        },

        scrollToHighlight(index) {
            const element = this.$refs[`highlight-${index}`];
            if (element && element[0]) { // ref array, assicurati che l'elemento esista
                element[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },

        setSign: function () {

            var that = this;

            console.log("INFO SIGNS: ", that.infosigned);

            var x = 0;
            var y = 0;
            var pageNumber = 0;


            for (var xx = 0; xx < that.infosigned.length; xx++) {


                if (pageNumber == 0) {

                    if (that.infosigned[xx].signed == 0) // posizione non firmata
                    {
                        x = that.infosigned[xx].coordXView;
                        y = that.infosigned[xx].coordYView;
                        pageNumber = that.infosigned[xx].page;
                    }

                }


            }


            if ((x != 0) && (y != 0)) {

                console.log("COORD X: ", x);
                console.log("COORD Y: ", y);
                console.log("PAGE: ", pageNumber);

                const newIndex = that.coordinates.length;

                that.currentIndex = newIndex;

                that.coordinates.push({
                    canvasX: x,
                    canvasY: y,
                    page: pageNumber,
                    signatureImg: null,
                    text1: null,
                    text2: null
                });

                that.$nextTick(() => {
                    that.scrollToHighlight(newIndex); // Chiama la funzione di scroll con l'indice
                });

                that.numPage = pageNumber;

                that.currentPage = pageNumber;

                setTimeout(() => {

                    that.updateCountSigns();

                }, 200);



            }


            if ((x == 0) && (y == 0)) {


                // that.$swal({
                //     icon: "warning",
                //     text: "Completed signatures save the document",
                //     showConfirmButton: false,
                //     timer: 3000
                // });

                setTimeout(() => {

                    that.saveData();

                }, 200);


            }





        },

        viewAttachment: async function () {

            var that = this;

            var path_file = "";

            if (window.currentItemRow.doc_cond == 0) {



                path_file = "s3/aziende/" + window.currentItemRow.val_cmp_dest + "/Products/Alertmsg/" + window.currentItemRow.attachment;

            }

            if (window.currentItemRow.doc_cond == 1) {

                path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/Condivisi/" + window.currentItemRow.attachment;

            }

            console.log("PATH FILE DOWN: ", path_file);

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.goGetAttachment(
                path_file,
                v_token,
                window.currentItemRow.attachment,
                window.currentItemRow.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from goGetAttachment", res);

                setTimeout(() => {

                    that.infosigned = res.data.InfoSigns;

                    that.pdfUrl = res.data.PathReturn;

                    setTimeout(() => {

                        console.log("INFOSIGNED: ", that.infosigned);



                        that.loadPdf();

                    }, 200);



                }, 100);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        validateOld() {

            var that = this;

            var errore = "";


            if (that.coordinates.length == 0) {
                errore = "Set at least one signature in the document";
            }

            if (errore == "") {

                window.coordSigns = JSON.stringify(that.coordinates_original);

                router.push({
                    path: "/filterSelectUsers"
                });

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                // var pulsantis = [

                //     {
                //         text: "Sign",
                //         icon: "mdi-content-save",
                //         link: "/timesheet",
                //         id: "btn_sign",
                //         disabled: false,
                //         image: this.getUrlDomain("public/_lib/img/firma_alertmsg.png"),
                //         title: "Sign",
                //         width: 30

                //     },


                //     {
                //         text: "Save",
                //         icon: "mdi-content-save",
                //         link: "/timesheet",
                //         id: "btn_save",
                //         disabled: false,
                //         image: this.getUrlDomain("public/_lib/img/save-alertmsg.png"),
                //         title: "Save",
                //         width: 30

                //     },


                // ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },




        async loadPdf() {

            var that = this;

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                this.totalPages = pdf.numPages;

                for (let pageNumber = 1; pageNumber <= this.totalPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    this.pages[pageNumber] = page;
                    const viewport = page.getViewport({ scale: this.scale });

                    const pdfCanvas = document.getElementById(`pdf-canvas-${pageNumber}`);
                    pdfCanvas.width = viewport.width;
                    pdfCanvas.height = viewport.height;
                    const context = pdfCanvas.getContext('2d');

                    await page.render({
                        canvasContext: context,
                        viewport: viewport
                    }).promise;

                    this.observePage(pdfCanvas, pageNumber);
                }

                this.pagesTotal = this.totalPages;

                this.updateCountSigns();

                setTimeout(() => {

                    that.setSign();

                }, 200);


            } catch (error) {
                console.error('Errore nel caricamento del PDF:', error);
            }
        },

        observePage(canvas, pageNumber) {
            var that = this;
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        //this.currentPage = pageNumber;
                        console.log(`Pagina corrente: ${pageNumber}`);

                        that.numPage = pageNumber;
                        that.currentPage = pageNumber;

                        setTimeout(() => {

                            that.updateCountSigns();

                        }, 100);


                    }
                });
            }, { threshold: 0.2 }); // Modifica threshold per adattare quando considerare visibile

            observer.observe(canvas);
        },

        async handleCanvasClick(event, pageNumber) {
            const page = this.pages[pageNumber];
            const viewport = page.getViewport({ scale: this.scale });
            const canvas = event.currentTarget;
            const rect = canvas.getBoundingClientRect();

            console.log("Canvas Width:", rect.width);
            console.log("Canvas Height:", rect.height);

            // Calcola l'offset cumulativo delle pagine sopra la pagina corrente
            let cumulativeOffset = 0;
            for (let i = 1; i < pageNumber; i++) {
                const previousPage = this.$refs[`pdfPage-${i}`][0];
                cumulativeOffset += previousPage.getBoundingClientRect().height;
            }

            // Calcola le coordinate relative
            const x = (event.clientX - rect.left) * (viewport.width / rect.width);
            const y = (event.clientY - rect.top + cumulativeOffset) * (viewport.height / rect.height);

            console.log("COORD X: ", x);
            console.log("COORD Y: ", y);


            this.coordinates.push({
                canvasX: x,
                canvasY: y,
                page: pageNumber
            });




            // Ottieni il viewport originale della pagina senza scala
            const originalViewport2 = page.getViewport({ scale: 1 });

            // Ottieni il bounding rect del canvas per calcolare l'offset rispetto al client
            const canvas2 = event.currentTarget;
            const rect2 = canvas2.getBoundingClientRect();

            // Ottieni le coordinate del click rispetto alle dimensioni originali del PDF
            const x2 = (event.clientX - rect2.left) * (originalViewport2.width / rect2.width);
            const y2 = (event.clientY - rect2.top) * (originalViewport2.height / rect2.height);

            console.log("Coordinate reali del PDF (100%):", { x2, y2 });

            this.coordinates_original.push({
                canvasX: x2,
                canvasY: y2,
                page: pageNumber
            });



        },
        removeHighlight(index) {
            this.coordinates.splice(index, 1);
            this.coordinates_original.splice(index, 1);
        },
        sendCoordinates() {
            fetch('http://localhost:5000/api/save_coordinates', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.coordinates)
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Coordinate inviate con successo:', data);
                })
                .catch(error => {
                    console.error('Errore durante l\'invio delle coordinate:', error);
                });
        }
    }
}
</script>

<style>
.text-container {
    display: flex;
    /* Imposta display flex */
    flex-direction: column;
    /* I figli saranno disposti in colonna */
    margin-top: 32px;
    /* Spaziatura sopra il testo, se desiderato */
}

.signature-text {
    font-size: 9px;
    margin: 0;
}

.mainTitleConfSign {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.highlight-box-set {
    width: 220px;
    height: 70px;
    background-color: rgba(255, 0, 0, 0);
    border: 1px solid #da6a2d;
    border-radius: 4px;
    cursor: pointer;
}

.close-box {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: white;
    background-color: black;
    border-radius: 50%;
    cursor: pointer;
    padding: 2px;
}

.signature-image {
    width: 200px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
}
</style>