import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIMessaggi {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainGo(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = "http://localhost:4011/";
    }

    if (location.hostname != "localhost") {

      result = "https://filestransfer.it:4011/";

    }


    return result + nome_metodo;

  }


  async getInfoMessaggioRef(user, id) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("AlertMsgGetInfoMsgRef"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttachment(v_path, v_user, v_file_name, v_id_message) {

    let formData = new FormData();

    formData.append("v_path", v_path);
    formData.append("v_user", v_user);
    formData.append("v_file_name", v_file_name);
    formData.append("v_id_message", v_id_message);

    return axios.post(this.getUrlDomain("AlertMsgGetAttachment"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async goGetAttachment(v_path, v_user, v_file_name, v_id_message) {

    let formData = new FormData();

    formData.append("v_path", v_path);
    formData.append("v_user", v_user);
    formData.append("v_file_name", v_file_name);
    formData.append("v_id_message", v_id_message);

    return axios.post(this.getUrlDomainGo("AlertMsgGetAttachment"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveSignedDocument(v_id_messaggio, v_data_signed, v_user) {

    let formData = new FormData();

    formData.append("v_id_messaggio", v_id_messaggio);
    formData.append("v_data_signed", v_data_signed);
    formData.append("v_user", v_user);

    return axios.post(this.getUrlDomain("AlertMsgSaveSignedDocument"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDataForOtp(v_user) {

    let formData = new FormData();

    formData.append("v_user", v_user);

    return axios.post(this.getUrlDomain("AlertMsgGetDataForOtp"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getValueForOtp(username, name_product, mobile, prefix_mobile) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("name_product", name_product);
    formData.append("mobile", mobile);
    formData.append("prefix_mobile", prefix_mobile);

    return axios.post(this.getUrlDomain("generateOtpAndSendSMS"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sendMailDocSigned(username, v_id) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("AlertMsgGetSendMailDocSigned"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async uploadFileForSetSign(username, v_file) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("v_file", v_file);

    return axios.post(this.getUrlDomain("AlertMsgUploadFileForSetSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async goUploadFileForSetSign(username, v_file) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("v_file", v_file);

    return axios.post(this.getUrlDomainGo("AlertMsgUploadFileForSetSign"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveDataSigns(v_user, v_id_message, v_info_signs) {

    let formData = new FormData();

    formData.append("v_user", v_user);
    formData.append("v_id_message", v_id_message);
    formData.append("v_info_signs", v_info_signs);

    return axios.post(this.getUrlDomain("AlertMsgSaveDataSigns"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getValueForOtpWithMailAndUser(v_mail, name_product, v_user) {

    let formData = new FormData();

    formData.append("v_mail", v_mail);
    formData.append("name_product", name_product);
    formData.append("v_user", v_user);

    return axios.post(this.getUrlDomain("generateOtpAndSendMailAlertMsg"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateRowVerMail(v_mail, v_utente) {

    let formData = new FormData();

    formData.append("v_mail", v_mail);
    formData.append("v_utente", v_utente);

    return axios.post(this.getUrlDomain("updateRowVerMail"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateRowVerPhone(v_utente, v_prefix, v_mobile) {

    let formData = new FormData();

    formData.append("v_utente", v_utente);
    formData.append("v_prefix", v_prefix);
    formData.append("v_mobile", v_mobile);

    return axios.post(this.getUrlDomain("updateRowVerPhone"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteMessage(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomainGo("deleteMessage"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIMessaggi({
  url: "https://services.ebadge.it/public/api/"
})
