import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIUploadFile {

    getUrlDomainGo(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = "http://localhost:4011/";
        }

        if (location.hostname != "localhost") {

            result = "https://filestransfer.it:4011/";

        }


        return result + nome_metodo;

    }



    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    async setFile(id, file, path_server, path_dox, tipo_upload, user, removeUploadFile, d_fatt, n_fatt, cmp_fatt, cmp_forn) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("file", file);
        formData.append("path_server", path_server);
        formData.append("path_dox", path_dox);
        formData.append("tipo_upload", tipo_upload);
        formData.append("user", user);
        formData.append("removeUploadFile", removeUploadFile);

        formData.append("d_fatt", d_fatt);
        formData.append("n_fatt", n_fatt);
        formData.append("cmp_fatt", cmp_fatt);
        formData.append("cmp_forn", cmp_forn);

        return axios.post(this.getUrlDomain("EmtUploadFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async setFileForn(id, file, path_server, path_dox, tipo_upload, user, removeUploadFile, d_fatt, n_fatt, cmp_fatt, cmp_forn) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("file", file);
        formData.append("path_server", path_server);
        formData.append("path_dox", path_dox);
        formData.append("tipo_upload", tipo_upload);
        formData.append("user", user);
        formData.append("removeUploadFile", removeUploadFile);

        formData.append("d_fatt", d_fatt);
        formData.append("n_fatt", n_fatt);
        formData.append("cmp_fatt", cmp_fatt);
        formData.append("cmp_forn", cmp_forn);

        return axios.post(this.getUrlDomain("EmtUploadFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async setFileAlertMsgUser(id, file, path_server, path_dox, tipo_upload, user, removeUploadFile, id_user_dest, title) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("file", file);
        formData.append("path_server", path_server);
        formData.append("path_dox", path_dox);
        formData.append("tipo_upload", tipo_upload);
        formData.append("user", user);
        formData.append("removeUploadFile", removeUploadFile);

        formData.append("id_user_dest", id_user_dest);
        formData.append("title", title);



        return axios.post(this.getUrlDomain("EmtUploadFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async getFile(id, tipo_upload, path_server, user) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("tipo_upload", tipo_upload);
        formData.append("path_server", path_server);
        formData.append("user", user);


        return axios.post(this.getUrlDomain("EmtGetFile"), formData, { 'Content-Type': 'multipart/form-data' });

    }




    async setFileAlertMsgUserNew(id, file, path_server, path_dox, tipo_upload, user, removeUploadFile, id_user_dest, title) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("file", file);
        formData.append("path_server", path_server);
        formData.append("path_dox", path_dox);
        formData.append("tipo_upload", tipo_upload);
        formData.append("user", user);
        formData.append("removeUploadFile", removeUploadFile);

        formData.append("id_user_dest", id_user_dest);
        formData.append("title", title);



        return axios.post(this.getUrlDomain("EmtUploadFileNew"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goSetFileAlertMsgUserNew(id, file, path_server, path_dox, tipo_upload, user, removeUploadFile, id_user_dest, title) {

        let formData = new FormData();

        formData.append("v_id", id);
        formData.append("file", file);
        formData.append("path_server", path_server);
        formData.append("path_dox", path_dox);
        formData.append("tipo_upload", tipo_upload);
        formData.append("user", user);
        formData.append("removeUploadFile", removeUploadFile);

        formData.append("id_user_dest", id_user_dest);
        formData.append("title", title);



        return axios.post(this.getUrlDomainGo("EmtUploadFileNew"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APIUploadFile({
    url: "https://services.ebadge.it/public/api/"
})
